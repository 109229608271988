body {
    /* background-color: #0e2c23; */
    max-height: fit-content;
    position: static;
    margin: 0;
    /* Reset default margin */
}

.column {
    padding: 15px;
    box-sizing: border-box;
}

h1 {
    color: #ffc107;
    margin: 0;
    margin-top: 10%;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
}

h2 {
    color: #fff;
    margin: 5%;
    font-size: 40px;
    text-align: center;
}

.main-container {
    width: 100%;
    display: flex;
    justify-content: center;

}




#chat-container {
    width: 100%;
    margin-top: 1%;
    height: 100%;
    background-color: rgb(9, 9, 9);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(60, 64, 60, 0.8), 0 0 20px rgba(49, 59, 52, 0.8), 0 0 30px rgba(58, 72, 62, 0.7);
    border-style: groove;
    border-color: rgba(51, 56, 51, 0.4);
    border-width: thick;
}

#chat-display {
    height: 370px;
    padding: 2% 1% 1% 2%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    background-color: whitesmoke;
    border-bottom: 0.5px solid #7d7f7d;
    scrollbar-color: rgb(136, 137, 136);
}

.options {
    background-color: #0649cf;
    width: 10rem;
    margin-left: 0.4%;
    color: whitesmoke;
    margin-bottom: 1%;
    border-radius: 3%;
}

.options:hover {
    background-color: #242a55;
}

.ge {
    margin-bottom: 2%;
}

.bot {
    font-size: medium;
    font-family: Arial;
    color: rgb(73, 176, 104);
}

.message {
    font-size: medium;
    font-family: Arial;
    color: rgb(1, 10, 4);
}

.user {
    font-size: medium;
    font-family: Arial;
    color: rgb(8, 7, 7);
}

.custom-button {
    background-color: #0649cf;
    color: whitesmoke;
    border: none;
    padding: 0.7%;
    margin: 1%;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    text-decoration: none;
}

.custom-button:hover {
    background-color: #131c2c;
    font-size: 15px;
}

.popup {
    display: none;
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #2b51e8;
    color: white;
    padding: 16px;
    border-radius: 5px;
    z-index: 1;
    animation: fadeIn 0.5s, fadeOut 0.5s 1s forwards;
}

.Errorpopup {
    display: none;
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #c92424;
    color: white;
    padding: 16px;
    border-radius: 5px;
    z-index: 1;
    animation: fadeIn 0.5s, fadeOut 0.5s 1s forwards;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    #chat-container {
        width: 100%;
        margin: 0;
        border-radius: 0;
    }

    #chat-display {
        height: 50vh;
        overflow-y: scroll;

    }

    .column {
        padding: 10px;
    }

    h1 {
        font-size: 1.5em;
        margin-top: 5%;
    }

    h2 {
        font-size: 1.2em;
        margin: 3%;
    }

    .custom-button {
        font-size: 0.8em;
    }
}

@media (max-width: 1000px) {
    #chat-container {
        width: 100%;
        margin: 0;
        border-radius: 0;
    }

    #chat-display {
        height: 50vh;
        overflow-y: scroll;

    }

    .column {
        padding: 10px;
    }

    h1 {
        font-size: 1.5em;
        margin-top: 5%;
    }

    h2 {
        font-size: 1.2em;
        margin: 3%;
    }

    .custom-button {
        font-size: 0.8em;
    }
}

.circle-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.circle {
    width: 900px;
    height: 900px;
    border-radius: 50%;
    background-color: #0e2c23;
}

@keyframes grow {
    0% {
        transform: scale(0);
        border-radius: 50%;
    }

    25% {
        border-radius: 40%;
    }

    50% {
        border-radius: 30%;
    }

    75% {
        border-radius: 30%;
    }

    100% {
        transform: scale(1);
        border-radius: 0%;
    }
}

.grow {
    animation: grow 2s ease forwards;
}





/* typin */



/* HTML: <div class="loader"></div> */
.loader {
    width: 25px;
    aspect-ratio: 1;
    --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%
    }

    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%
    }

    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%
    }

    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%
    }
}

/* Select */
.basic-multi-select {
    color: black;
    width: 100%;
}